<template>
	<div>
		<v-row>
			<v-col >
				<s-crud
			title="Visitas"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridVisit"
			@rowSelected="rowSelected($event)" 
			searchInput
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col lg="3">
							<s-date
								label="Fecha Inicio"
								v-model="filter.PrvBeginDate"
							></s-date>
						</v-col>
						<v-col lg="3">
							<s-date
								label="Fecha Fin"
								v-model="filter.PrvEndDate"
							></s-date>
						</v-col>
                        <v-col cols="6" md="3" lg="3" >											
						    <s-text
								label="Número de Documento"
								v-model="filter.PrvDocumentNumber"
								number
							></s-text>
                        </v-col>						            			
						<v-col cols="6" md="3" lg="3" >											
							<s-select-definition
								label="Nombre de la Empresa"
								:add="$fun.isAdmin()"
								:def="1471"
								v-model="filter.TypeCompany"
								clearable
							/>
                        </v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="3" lg="3" >
							<v-btn small="" block color="primary" @click="openDialogMassive()">
								Guardar Masivo
							</v-btn>
						</v-col>
					</v-row>
					
				</v-container>
			</template>
			
			
			<template scope="props">
				<v-container>
					<v-row >						
						<v-col cols="6" md="2" lg="2" >
							<s-date
								label="Fecha de Visita" 
								v-model="props.item.PrvDateVisit" 
							></s-date>
						</v-col>
						<v-col cols="6" md="3" lg="3">
							<s-toolbar-person  readonly noCamera :defaultDocument="props.item.PrvDocumentNumber" @returnPerson="returnPerson($event, props)" v-model="props.item.PrvDocumentNumber" />
						</v-col>

						<v-col cols="6" md="4" lg="4">
							<s-text readonly label="Persona" v-model="props.item.NtpFullName"></s-text>
							<s-text hidden v-model="props.item.NtpID"></s-text>
						</v-col>						
						<v-col cols="6" md="3" lg="3">
							<s-select-definition
								label="Nombre de la Empresa"
								:add="$fun.isAdmin()"
								:def="1471"
								v-model="props.item.TypeCompany"							
							/>
						</v-col>
						
					</v-row>
					
				</v-container>
			</template>

				</s-crud>
			</v-col>
		</v-row>
		<v-dialog v-if="dialogSaveMassive" v-model="dialogSaveMassive" width="60%" persistent  hide-overlay formPermanent  >
					<personVisitMassive @EndProcess="endProcessChange($event)" > 

					</personVisitMassive>
		</v-dialog>	
	</div>
	
</template>
<script>
	import _PersonVisitService from '@/services/General/GenPersonVisitService.js';
	import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
	import _PersonService from "@/services/General/PersonService";
	import personVisitMassive from './PersonVisitMassive.vue';

	export default {
    components: { sToolbarPerson , personVisitMassive },
    props: { },
		data() {
			return {
				documents: [],
				person  :[],
				filter: {
					//PrvDateVisit,
                    //PrvDocumentNumber,
                    //PrvCompany
				},
				dialogChange: false,
				dialogSaveMassive: false,
				headers: [
					{
						column	: 	"DOCUMENTO",
						value	: 	"DOCUMENTO",
						text 	: 	"DOCUMENTO"
					},	
					{
						column	: 	"AP",
						value	: 	"AP",
						text 	: 	"AP"
					},
					{
						column	: 	"AM",
						value	: 	"AM",
						text 	: 	"AM"
					},	
					{
						column	: 	"NOMBRE",
						value	: 	"NOMBRE",
						text 	: 	"NOMBRE"
					},	
					{
						column	: 	"EMPRESA",
						value	: 	"EMPRESA",
						text 	: 	"EMPRESA"
					},	
					{
						column	: 	"FV",
						value	: 	"FV",
						text 	: 	"FV",
						date	: 	true
					},				
				 ],
				config: {
                    title: "Visitas",
					model: {
						PrvID: "ID",
						NtpFullName: "string",
						PrvDocumentNumber : "string",
                        PrvDateVisit : "date",                       
                        TypeCompanyName : "string"
					},
					service: _PersonVisitService,
					headers: [
						{text: "ID", value: "PrvID"},											
						{text: "Persona", value: "NtpFullName"},					
						{text: "Número de Documento", value: "PrvDocumentNumber"},
						{text: "Fecha de Visita", value: "PrvDateVisit"},
						{text: "Nombre de la Empresa", value: "TypeCompanyName"}

					]
				},
				
			}
		},
		watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			initialize()
			{
				
			},

			save(item) {				
				if (this.validatedPerson(item)) {
					return;
				}

				console.log("item save", item);
				if(item.SecStatus == null) item.SecStatus = 1

				_PersonVisitService.searchByDocAndDate(item,this.$fun.getUserID()).then((r) => {
					console.log("data",r.data);
					let data = r.data
					if(data.length>0) { 
						if(data[0].PrvDocumentNumber === item.PrvDocumentNumber &&  this.$moment(data[0].PrvDateVisit).format("YYYY-MM-DD") === item.PrvDateVisit && data[0].PrvID === item.PrvID){
							console.log("Paso a editar")
							item.save();
						}
						else{
							this.$fun.alert("Ya se registro la persona con DNI: "+  data[0].PrvDocumentNumber + " con la misma fecha de visita: " + this.$moment(new Date(data[0].PrvDateVisit)).format("DD-MM-YYYY") , "warning"); 
							return;
						}
					}
					else{
						item.save();
					}
				});
				
			},

			validateVisit(){
				let isValid = false;
				let message = "";

				isValid = item.NtpID === "" && item.NtpFullName === "" ;
				if (isValid) message = "No se ha seleccionado a una Persona";
			},

			rowSelected(items){
				if(items.length > 0){ this.selected = items[0]; }
			},

			returnPerson(value, props) {
				console.log("Retornar" , value);
				props.item.NtpFullName = value.NtpFullName;
				props.item.NtpID = value.NtpID;
			},
			validatedPerson(item) {
				let isValid = false;
				let message = "";

				isValid = item.NtpID === "" && item.NtpFullName === "" ;
				if (isValid) message = "No se ha seleccionado a una Persona";

				if (isValid) this.$fun.alert(message, "warning");

				return isValid;
			},

			endProcessChange(rpt){
				this.dialogSaveMassive = false;
			
				if (rpt != null) {
					this.$refs.gridVisit.refresh();					
				}
			},
			clear(event){
				this.person = [];
				this.documents = [];
				
			},
			openDialogMassive(){
				this.dialogSaveMassive = true;
				console.log("Abrir open")
			},
		}
		
	};
</script>

<style scoped>

</style>